form {
  label.required {
    &:after {
      display: block;
      content: "*";
      color: $state-danger-text;
      margin: 3px 0 0 3px;
      float: right;
    }
  }
}
